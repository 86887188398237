<template>

    <div>
      <img
      src="../assets/welcomePage.jpg"
      class="full-page-image"
      alt="Full-page background"
    />
    <div class="blur-layer"></div>
        <img src="../assets/thankyou.png" alt="" style="" class="image">
        <!-- <p style="font-size: 27px;font-weight: 700;text-align: center;color:black">Thank you for sharing your inputs to transform Sivasagar</p>
        <p style="font-size: 16px;font-weight: 400;text-align: center;color:#343A40;"> we will reach out you for any queries</p> -->
        <!-- <p style="font-size: 26px;font-weight: 700;text-align: center;color:white;line-height: 30px;">শিৱসাগৰক ৰূপান্তৰিত কৰিবলৈ আপোনাৰ মতামতসমূহ শ্বেয়াৰ কৰাৰ বাবে ধন্যবাদ</p> -->
        <p style="font-size: 26px;font-weight: 700;text-align: center;color:white;line-height: 30px;">আপোনাৰ পৰামৰ্শৰ বাবে ধন্যবাদ</p>
        <p style="font-size: 16px;font-weight: 400;text-align: center;color:white;margin-top: 15px;">যিকোনো প্ৰশ্নৰ বাবে আমি আপোনাৰ সৈতে যোগাযোগ কৰিম</p>
        <!-- <b-button style="background-color: rgba(255, 204, 0, 1);border-radius: 33px;box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.15);border:none;" ><span style="color: black;font-size: 22px;font-weight: 700;">Give one more input</span></b-button> -->
        <b-button style="background-color: rgba(255, 204, 0, 1);border-radius: 33px;box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.15);border:none;margin-top: 100px" @click="toggleForm()"><span style="color: black;font-size: 22px;font-weight: 700;">আপোনাৰ মতামত দিয়ক</span></b-button>

        <!-- <p style="font-size: 15px;color: black;margin-top: 100px;">Powered by</p> -->
        <p style="font-size: 15px;color: white;margin-top: 100px;margin-bottom: 4px;">দ্বাৰা চালিত</p>
        <img src="../assets/jana_logo.png" alt="" style="
    line-height: 4px;">
    </div>
</template>
<script>
export default {
  methods: {
    toggleForm() {
      // Navigating to the target page, replace 'TargetPage' with the actual route name
      this.$router.replace({ name: 'about' });
    }
  }
}
</script>
<style scoped>
.image{
    width: auto;
    margin-bottom: 20px;
    margin-top: 40px;
}
@media (max-width: 768px) {
    .image{
    width: auto;
    margin-bottom: 100px;
    margin-top: 93px;
} 
}
.full-page-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.blur-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(78, 78, 78, 0.01); /* Adjust color and transparency */
  backdrop-filter: blur(10px); /* Apply blur effect */
  z-index: -1; /* Make sure it's above the image but below content */
}
</style>